
import Vue from "vue"
import { required } from "vee-validate/dist/rules"
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate"
import { CustomerEnum, ClientEnum } from "@/types/enums"
import { Client, Person, AclExtract } from "@/types/responses"


/************************
 * VALIDATION OBSERVERS *
 ***********************/
setInteractionMode("eager")
extend("required", {
	...required,
	message: "{_field_} cannot be empty",
})

export default Vue.extend({
	name: "SelectPrimaryContact",
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	mounted() {
		this.getUsers()
	},
    props: {
        clientPersons: {
            type: Boolean,
            required: true
        }
    },
	data(): {
		snackBar: boolean
		snackBarText: string
		dialog: boolean
		newPrimaryContactId: string | null
		loading: boolean
		customerenm: typeof CustomerEnum
		clientenm: typeof ClientEnum
		timeLeft: number
	} {
		return {
			snackBar: false,
			snackBarText: "",
			dialog: false,
			newPrimaryContactId: null,
			loading: false,
			customerenm: CustomerEnum,
			clientenm: ClientEnum,
			timeLeft: 3,
		}
	},
    watch:{
        persons: {
            deep: true,
            handler(){
                console.log("persons updated")
            }
        }
    },
	computed: {
		getAcl(): AclExtract {
			if(this.$route.name?.includes(this.customerenm.Customer)){
				return this.$store.state.acl[this.customerenm.Customer]
			}
			return this.$store.state.acl[this.clientenm.ClientOrganization]
		},
		enm(): typeof CustomerEnum | typeof ClientEnum {
			if(this.$route.name?.includes(this.customerenm.Customer)){
				return this.customerenm
			}

			return this.clientenm
		},
		displayPrimaryContactAcl(): boolean {
			if(
				!this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.DisplayPrimaryContact)
			} else if (
				this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ClientDisplayPrimaryContact)
			} 

			return this.getAcl.ui.includes(this.clientenm.ClientDisplayPrimaryContact)
		},
		viewPrimaryContactAcl(): boolean {
			if(
				!this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ViewPrimaryContact)
			} else if (
				this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ClientViewPrimaryContact)
			} 

			return this.getAcl.ui.includes(this.clientenm.ClientViewPrimaryContact)
		},
		managePrimaryContactAcl(): boolean {
			console.log(this.$route.name)
			if(
				!this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ManagePrimaryContact)
			} else if (
				this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ClientManagePrimaryContact)
			} 
			
			return this.getAcl.ui.includes(this.clientenm.ClientManagePrimaryContact)
		},
		persons(): Person[] {
            if(this.clientPersons){
                const hit = this.$vStore.state.context.clientPersons.find(client => {
                    return client.clientId === this.selectedClient
                })
                if(hit) return hit.persons;
            } else {
                if(this.$vStore.state.context!.persons) return this.$vStore.state.context.persons
            }
            
            return []
		},
		selectedClient(): string {
			return this.$vStore.state.context.selectedClient!.id;
		},
		currentPrimaryContactId(): string|null {
			const hit = this.persons.find(person => {
				return person.isPrimaryContact === true
			})
			return hit ? hit.id : null
		},
		primaryContactSelectList(): Array<{text: string, value: string|null}> {
			const list: Array<{ text: string, value: string|null }> = this.persons.map(person => {
				return {text: person.displayName, value: person.id}
			})
			const noOne = { text: "No primary contact", value: null }
			list.unshift(noOne)
			return list
		},
		newPrimaryContactDisplayName(): string {
			const hit = this.persons.find(person => {
				return person.id === this.newPrimaryContactId
			})
			return hit ? hit.displayName : ""
		},
		currentPrimaryContactDisplayName(): string {
			const hit = this.persons.find(person => {
				return person.id === this.currentPrimaryContactId
			})
			return hit ? hit.displayName : "No primary contact"
		}
	},
	methods: {
		async countDownTimer(): Promise<void> {
			this.timeLeft = 3
			return new Promise((resolve) => {
				const downloadTimer = setInterval(() => {
					this.timeLeft--
					this.snackBarText = `Successfully updated the primary contact.<br/><br/>Will reload permissions in ${this.timeLeft} seconds.. `
					if (this.timeLeft <= 0) {
						clearInterval(downloadTimer)
						resolve()
					}
				}, 900)
			})
		},
		reset() {
			this.newPrimaryContactId = this.currentPrimaryContactId
		},
		async getUsers(): Promise<void> {
			if (this.persons.length > 0) {
				this.newPrimaryContactId = this.currentPrimaryContactId
				return
			} else {
				this.loading = true

				if(this.clientPersons) await this.getClientPersons()
                else await this.getPersons()

				this.newPrimaryContactId = this.currentPrimaryContactId
				this.loading = false
			}
		},
        async getPersons(): Promise<void> {
			await this.$vStore.dispatch("context/updatePersons")
        },
        async getClientPersons(): Promise<void> {
            await this.$vStore.dispatch("context/updateClientPersons")
        },
		confirm(): void {
			this.dialog = true
		},
		cancel(): void {
			this.reset()
			this.dialog = false
			this.loading = false
		},
        async updatePrimaryContact(): Promise<void> {
                if(this.clientPersons) await this.updateClientPrimaryContact()
                else await this.updateCustomerPrimaryContact()
        },
        async updateClientPrimaryContact(): Promise<void> {
			this.loading = true
			try {
				// eslint-disable-next-line
				const isValid = await (this.$refs.clientObserver as any).validate()
				if (!isValid) return
				//if (this.newPrimaryContactId && (this.$vStore.state.context.persons.find(person => person.id === this.newPrimaryContactId) !== undefined) ) {
					this.$vStore.dispatch("context/setClientPrimaryContact", this.newPrimaryContactId)

					this.dialog = false
					this.snackBarText = `Successfully updated the primary contact.<br/><br/>Will reload permissions in ${this.timeLeft} seconds.. `
					this.snackBar = true
					await this.countDownTimer()
					// Reset validators! You should call it on the next frame
					requestAnimationFrame(() => {
						// eslint-disable-next-line
						if (this.$refs.clientObserver) {
							// eslint-disable-next-line
							;(this.$refs as any).clientObserver.reset()
						}
					})

					/************************/
					/** START FROM SCRATCH **/
					/************************/
					this.$vStore.dispatch("context/resetContext")
					this.$vStore.dispatch("acl/resetState")
					this.$router.push({name: "Context"})
				//} else {
				//	this.snackBar = true
				//	this.snackBarText = "No change in primary contact"
				//}
			} catch (e) {
				this.reset()
				this.snackBar = true
				this.snackBarText = "Something happened while updating the primary contact!.<br/><br/>Error: " + JSON.stringify(e)
			}
			this.loading = false
			this.dialog = false
		},
        async updateCustomerPrimaryContact(): Promise<void> {
			this.loading = true
			try {
				// eslint-disable-next-line
				const isValid = await (this.$refs.observer as any).validate()
				if (!isValid) return
				//if (this.newPrimaryContactId && (this.$vStore.state.context.persons.find(person => person.id === this.newPrimaryContactId) !== undefined) ) {
					this.$vStore.dispatch("context/setPrimaryContact", this.newPrimaryContactId)

					this.dialog = false
					this.snackBarText = `Successfully updated the primary contact.<br/><br/>Will reload permissions in ${this.timeLeft} seconds.. `
					this.snackBar = true
					await this.countDownTimer()
					// Reset validators! You should call it on the next frame
					requestAnimationFrame(() => {
						// eslint-disable-next-line
						if (this.$refs.observer) {
							// eslint-disable-next-line
							;(this.$refs as any).observer.reset()
						}
					})

					/************************/
					/** START FROM SCRATCH **/
					/************************/
					this.$vStore.dispatch("context/resetContext")
					this.$vStore.dispatch("acl/resetState")
					this.$router.push({name: "Context"})
				//} else {
				//	this.snackBar = true
				//	this.snackBarText = "No change in primary contact"
				//}
			} catch (e) {
				this.reset()
				this.snackBar = true
				this.snackBarText = "Something happened while updating the primary contact!.<br/><br/>Error: " + JSON.stringify(e)
			}
			this.loading = false
			this.dialog = false
		},
	},
})
